import { padStart } from "lodash";

export default (duration) => {
  let seconds = parseInt((duration / 1000) % 60),
    minutes = parseInt((duration / (1000 * 60)) % 60),
    hours = parseInt((duration / (1000 * 60 * 60)) % 24);

  let p = "";
  if (hours > 0) {
    p = p + padStart(hours, 2, 0) + ":";
  }
  if (hours > 0 || minutes > 0) {
    p = p + padStart(minutes, 2, 0) + ":";
  }
  return p + padStart(seconds, 2, 0);
};
