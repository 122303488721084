import { padStart } from "lodash";

export default (durationLiveLook) => {
  let seconds = parseInt((durationLiveLook / 1000) % 60),
    minutes = parseInt((durationLiveLook / (1000 * 60)) % 60),
    hours = parseInt(durationLiveLook / (1000 * 60 * 60));

  if (durationLiveLook <= 1000) {
    return parseInt(durationLiveLook / 1000) + "s";
  }

  let p = "";
  if (hours > 0) {
    p = p + padStart(hours, 2, 0) + "h ";
  }
  if (hours > 0 || minutes > 0) {
    p = p + padStart(minutes, 2, 0) + "m ";
  }

  let value = (p + padStart(seconds, 2, 0) + "s").replace(/^0+/, "");

  if (value === "s") {
    value = "0s";
  }

  return value;
};
