<template>
  <Modal
    v-model="isMdlShow"
    :footer-hide="true"
    :width="400"
    :mask-closable="false"
    @on-cancel="onDismiss"
    :closable="false"
    :class-name="className">
    <Row>
      <i-col
        :span="24"
        class="loader-icon">
        <div class="request-loader">
          <div class="loader-holder">
            <Avatar
              v-if="isQueueCallback"
              size="large"
              style="width: 70px; height: 70px"
              :src="callbackIcon" />
            <i
              v-else
              class="fad"
              :class="displayIcon" />
          </div>
        </div>
      </i-col>
      <i-col :span="24">
        <h6
          align="center"
          style="color: #aaa">
          {{ mIncomingMsg }}
        </h6>
        <h1
          align="center"
          style="margin-top: 5px; font-size: 27px; font-weight: bolder">
          <b
            :style="displayTextStyle"
            id="incomingNumber"
            ref="incomingNumber"
            >{{ displayText }}</b
          >
        </h1>
        <h6
          class="h6-call"
          align="center"
          v-if="mTag == 'call'"
          style="color: #aaa">
          <b>{{ displayQueue }}</b>
        </h6>
        <template v-if="mTag === 'chat'">
          <h6
            class="h6-call"
            align="center"
            style="color: #aaa">
            <b>{{ mdlData.queue ? mdlData.queue.name : "" }}</b>
          </h6>

          <p
            class="chat-customer-name"
            align="center"
            style="color: #aaa">
            <b>{{ mdlData.customerName }}</b>
          </p>
        </template>

        <template v-if="mTag === 'sms'">
          <h6
            class="h6-call"
            align="center"
            style="color: #aaa">
            <b>{{ mdlData.MatchedQueueName ? mdlData.MatchedQueueName : "" }}</b>
          </h6>

          <p
            class="chat-customer-name"
            align="center"
            style="color: #aaa">
            <b>{{ mdlData.customerName }}</b>
          </p>
        </template>

        <h6
          class="h6-email"
          align="center"
          v-if="mTag == 'email'"
          style="color: #aaa">
          <b>{{ displayAgentEmail }}</b>
        </h6>
        <h6
          class="h6-email2"
          align="center"
          v-if="mTag == 'email'"
          style="color: #aaa">
          <b>{{ displayEmailFlowName }}</b>
        </h6>
        <h6
          align="center"
          class="h6-sms-email"
          v-if="mTag == 'email'"
          style="color: #aaa">
          <QueueRenderer
            :data="displayQueue"
            :queues="agentSummary.Queues"
            :rand-colors="randColors"
            :queue-tags-arn="queueTagsArn"
            queue-name="Queue"></QueueRenderer>
        </h6>
      </i-col>
      <i-col
        :span="24"
        style="margin-top: 3px">
        <div
          v-if="autoAcceptCall"
          style="margin: 3px 0px; text-align: center">
          <b>{{ $t("common.autoAnswer") }}: {{ $t("common.enable") }}</b>
        </div>
        <circular-count-down-timer
          class="count-down-timer"
          v-if="isMdlShow"
          :paused="isAccepting"
          :initial-value="countDownTimerValue"
          :steps="20"
          :stroke-width="4"
          :size="60"
          :padding="0"
          second-label
          :seconds-stroke-color="darkMode ? '#7289DA' : '#04415A'"
          :underneath-stroke-color="darkMode ? '#72767d' : '#ddd'"
          :notify-every="'second'"
          @finish="onCountDownTimerFinish"
          @update="onTimerIncrement" />
      </i-col>
      <i-col
        v-show="!autoAcceptCall"
        :span="24"
        style="margin-top: 12%; margin-bottom: 25px">
        <Row
          v-if="!(!isSoftPhoneEnabled && mTag == 'call')"
          type="flex"
          justify="center">
          <i-col
            span="5"
            style="text-align: center">
            <Button
              id="btnDecline"
              ref="btnDecline"
              class="action-buttons"
              size="large"
              shape="circle"
              type="default"
              @click="$emit('decline', 'REJECTED')"
              :loading="$wait.is('rejectingContact')">
              <i
                v-if="!$wait.is('rejectingContact')"
                class="fal fa-times" />
            </Button>
            <p
              align="center"
              id="declineCall"
              ref="declineCall">
              {{ $t("common.decline") }}
            </p>
          </i-col>
          <i-col
            span="5"
            style="text-align: center">
            <Button
              id="btnAccept"
              ref="btnAccept"
              class="action-buttons"
              size="large"
              shape="circle"
              type="primary"
              @click="$emit('accept')"
              :loading="isAccepting">
              <i
                v-if="!isAccepting"
                class="fal fa-check" />
            </Button>
            <p
              align="center"
              id="acceptCall"
              ref="acceptCall">
              {{ $t("modal.accept") }}
            </p>
          </i-col>
          <div
            v-show="displayAttributes"
            class="attribute-wrapper">
            <h6 class="attribute-header">{{ $t("modal.contactAttributes") }}</h6>
            <ul>
              <li
                style="display: block; font-size: 16px"
                v-for="attribute in contactAttributes"
                class="truncate"
                :key="attribute.name">
                {{ !isCustomAttribute(attribute.name) ? $t(`notifications.incomingModal.${attribute.name}`) : attribute.name }}:
                <span
                  v-if="!isUrl(attribute.value + '', { require_protocol: true })"
                  style="font-weight: 500"
                  >{{ attribute.value }}</span
                >
                <span
                  style="font-weight: 500"
                  v-else>
                  <a
                    :href="attribute.value"
                    target="_blank">
                    {{ attribute.value }}
                  </a>
                </span>
              </li>
            </ul>
          </div>
        </Row>
        <Row
          v-else
          type="flex"
          justify="center"
          >{{ $t("common.answer") }} @ {{ deskphone }}</Row
        >
      </i-col>
    </Row>
  </Modal>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import callbackUIIcon from "@/assets/callback.png";
import { get } from "lodash";
import isURL from "@/utils/strings/isURL";

export default {
  name: "IncomingModal",
  data() {
    return {
      mdlShow: true,
      incomingMsg: "Call",
      isUrl: isURL,
      callbackIcon: callbackUIIcon,
      randColors: ["blue", "green", "purple", "magenta"],
      displayAttributes: false,
      durationContactIsPresented: 0,
      modalAttributes: ["Channel", "ChannelName", "From", "Subject", "To", "Body", "IsRecording", "NotifyDextr"],
    };
  },
  props: {
    mdlData: {
      type: Object,
      required: true,
    },
    isAccepting: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.isActiveHeadsetAdapter) this.sendCommandWithArguments({ cmd: "ringCmd", value: true });
  },
  computed: {
    ...mapActions("hid", ["sendCommandWithArguments"]),
    ...mapState("settings", ["darkMode"]),
    ...mapGetters("settings", ["getContactAttributesFilter"]),
    ...mapGetters(["agentSummary", "agent", "currentUser"]),
    ...mapGetters("hid", ["isActiveHeadsetAdapter"]),
    autoAcceptCall() {
      let c = this.currentUser;
      return get(c, "PhoneConfig.AutoAccept", false);
    },
    countDownTimerValue() {
      if (this.autoAcceptCall) {
        return 3;
      }
      return 20;
    },
    queueTagsArn() {
      return this.$store.state.agent.queuetags;
    },
    isQueueCallback() {
      return this.mdlData && this.mdlData?.type === "queue_callback" ? true : false;
    },
    mTag() {
      return this.tag;
    },
    displayIcon() {
      if (this.mTag == "sms") return "fa-sms";
      if (this.mTag == "email") return "fa-envelope";
      if (this.mTag == "chat") return "fa-comment-dots";
      if (this.mTag == "call" && this.isQueueCallback) return "fa-exchange";
      if (this.mTag == "task") {
        if (this.mdlData?.attributes?.Channel?.value === "voicemail") return "fa-voicemail";
        else return "fa-briefcase";
      }
      return "fa-phone-volume";
    },
    displayFlow() {
      return "Display Flow";
    },
    contactAttributes() {
      if (this.mdlData?.attributes && Object.keys(this.mdlData.attributes).length) {
        this.displayAttributes = true;

        const attributesCopy = JSON.parse(JSON.stringify(this.mdlData.attributes));

        delete attributesCopy.BodyId;
        delete attributesCopy.BodyUrl;
        delete attributesCopy.ChannelId;
        delete attributesCopy.ConversationRange;
        delete attributesCopy.ConversationId;
        delete attributesCopy.ConversationProvider;

        this.getContactAttributesFilter.forEach(({ ContactAttributeKey }) => {
          if (Object.prototype.hasOwnProperty.call(attributesCopy, ContactAttributeKey)) {
            delete attributesCopy[ContactAttributeKey];
          }
        });

        return attributesCopy;
      } else {
        this.displayAttributes = false;
      }

      return {};
    },
    displayText() {
      if (this.mdlData && "CustomerEmail" in this.mdlData) return this.mdlData.CustomerEmail;
      if (this.mdlData && "phoneNumber" in this.mdlData) return this.mdlData.phoneNumber;
      if (this.mdlData && "From" in this.mdlData) return this.mdlData.From;
      return "";
    },
    displayQueue() {
      if (this.mdlData && "queue" in this.mdlData) return this.mdlData.queue;
      if (this.mTag == "sms" && "MatchedQueueArn" in this.mdlData) {
        return {
          Queue: [this.mdlData.MatchedQueueArn],
        };
      }
      if (this.mTag == "email" && "MatchedQueueArn" in this.mdlData) {
        return {
          Queue: [this.mdlData.MatchedQueueArn],
        };
      }
      return "";
    },
    displayAgentEmail() {
      if (this.mdlData && "AgentEmail" in this.mdlData) return this.mdlData.AgentEmail;
      return "";
    },
    displayTextStyle() {
      if (this.tag !== "email") {
        return {};
      }

      let emailLength = this.displayText.length;

      if (emailLength <= 17) {
        return {};
      }

      let overflowLength = emailLength - 17;

      let fontSize = 1.7 / Math.log(overflowLength / 0.8 + 4);

      return {
        fontSize: `${fontSize}em`,
      };
    },
    displayEmailFlowName() {
      return "";
    },
    mIncomingMsg() {
      if (this.mTag == "sms") return this.$t("engage.incomingTextMessage");
      if (this.mTag == "call" && this.isQueueCallback) return this.$t("common.callback");
      return `${this.$t("common.incoming")} (${this.mTag.charAt(0).toUpperCase()}${this.mTag.slice(1)})`;
    },
    isMdlShow: {
      get() {
        this.mdlShow = this.show;
        return this.mdlShow;
      },
      set(v) {
        this.mdlShow = v;
      },
    },
    isSoftPhoneEnabled() {
      return this.agent.configuration.softphoneEnabled;
    },
    deskphone() {
      return this.agent.configuration.extension;
    },
    className() {
      if (this.darkMode) {
        return "mdl-incoming dark-incoming-modal";
      } else {
        return "mdl-incoming";
      }
    },
  },
  beforeDestroy() {
    this.$wait.end("rejectingContact");
    this.$emit("stop-ringer");
  },
  methods: {
    onTimerIncrement(timer) {
      this.durationContactIsPresented = timer.value;
    },
    onDismiss() {
      this.isMdlShow = false;
    },
    onCountDownTimerFinish() {
      if (this.autoAcceptCall) {
        this.$emit("accept");
        return;
      }

      this.$store.dispatch("logs/addItem", {
        component: "DEXTR",
        level: "INFO",
        text: `Agent did not click accept contact within 20 seconds`,
      });
      if (this.mdlData.type === "chat") {
        this.$emit("missed", "MISSED");
      } else {
        this.$emit("decline", "MISSED");
      }
    },
    isCustomAttribute(attribute) {
      return !this.modalAttributes.includes(attribute);
    },
  },
};
</script>

<style lang="scss">
.chat-customer-name {
  margin-top: 14px;
  font-size: 28px;
}

.attribute-wrapper {
  width: 90%;
  margin: 10px auto;
  color: #aaa;
  padding: 10px;
  font-weight: bolder;
  font-size: 14px;
  color: #6c6c6c;

  .attribute-header {
    font-size: 20px;
    font-weight: bolder;
    text-align: center;
  }
}

.mdl-incoming .ivu-modal-body {
  background: #f1f1f1;
  position: relative;
  padding: 15px 0;
  border-radius: 10px;

  .loader-icon {
    display: flex;
    justify-content: center;
    padding: 25px;
  }

  .action-buttons {
    height: 55px;
    width: 55px;

    > span > i,
    > i {
      font-size: 23px;
    }
  }

  .count-down-timer {
    margin: 15px 0px 0px 0px;
    .item {
      div {
        text-align: center;
        font-size: 14px !important;
        font-weight: bold;
      }
    }
  }
}

@mixin rings($duration, $delay) {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0;
  content: "";
  height: 100%;
  width: 100%;
  border: 4px solid rgba(0, 0, 0, 0.5);
  border-radius: 100%;
  animation-name: ripple;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  z-index: 1;
}

.request-loader {
  position: relative;
  display: flex;
  flex-direction: row;
  z-index: 2;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  border-radius: 100%;
  background: #ffffff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
  .loader-holder {
    height: 100px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    background: #fff;
    width: 100px;
    border-radius: 100%;

    i {
      position: relative;
      font-size: 50px;
    }
  }
  &::after {
    @include rings(3s, 1.5s);
  }
  &::before {
    @include rings(3s, 1s);
  }
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }
  to {
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1);
  }
}
</style>

<style lang="scss">
.dark-incoming-modal {
  .ivu-modal-body {
    background: #18191c;
    h6,
    p {
      color: #b9bbbe !important;
    }
    b {
      color: #dcddde !important;
    }

    .count-down-timer {
      div {
        color: #b9bbbe !important;
      }
    }

    .loader-holder {
      background: #7289da;
      border: none;
      width: 90px;
      height: 90px;
    }
  }

  .request-loader::before,
  .request-loader::after {
    border-color: #b9bbbe !important;
  }
}
</style>

<style lang="scss" scoped>
.attribute-list {
  margin-top: 15px;
}
</style>
